/*** WALKTHROUGH THEME OVERRIDES ***/

[data-theme="walkthrough"] {
  font-family: monospace !important;
}

/*** COLOURS ***/
[data-theme="walkthrough"] body {
  background-color: $walkthrough-bg-color-lightmode;
  color: $walkthrough-body-color-lightmode;
  @media (prefers-color-scheme: dark) {
    background-color: $walkthrough-bg-color-darkmode;
    color: $walkthrough-body-color-darkmode;
  }
}

[data-theme="walkthrough"] .topnav {
  
  background-color: $walkthrough-topnav-bg-lightmode;
  li {
    display: inline-flex;
  }
  li > a {
    color: $link;
    &.nav-active {
      background-color: $walkthrough-topnav-active-lightmode !important;
      font-weight: bold;
    }
  }
  @media (prefers-color-scheme: dark) {
    background-color: $walkthrough-topnav-bg-darkmode;
    li > a {
      color: $link;
      @media (prefers-color-scheme: dark) {
        color: $link-dark;
      }
      &.nav-active {
        background-color: $walkthrough-topnav-active-darkmode !important;
        font-weight: bold;
      }
    }
  }
}

[data-theme="walkthrough"] pre {
  
  color: $walkthrough-body-color-lightmode;
  @media (prefers-color-scheme: dark) {
    color: $walkthrough-body-color-darkmode;
  }
}

/*** LAYOUT ***/

[data-theme="walkthrough"] .container {
  margin: 0 !important;
  max-width: 100% !important;
}

[data-theme="walkthrough"] .ascii-art {
  display: block !important;
}

[data-theme="walkthrough"] pre {
  border: none !important;
  
  background-color: $walkthrough-bg-color-lightmode !important;
  @media (prefers-color-scheme: dark) {
    background-color: $walkthrough-bg-color-darkmode !important;
  }
}

[data-theme="walkthrough"] .longtext {
  font-size: 1rem;
  font-family: monospace !important;
  text-shadow: none;
}

// [data-theme="walkthrough"] main {
//   &::before {
//     content: "========================";
//   }
// }

[data-theme="walkthrough"] a {
  &::before {
    content: "[";
  }
  &::after {
    content: "]";
  }
  &:empty {
    &::before, &::after {
      content: "";
    }
  }
}

[data-theme="walkthrough"] .footer-heading {
  &::before, &::after {
    content: "==";
  }
}

[data-theme="walkthrough"] h1 {
  &::before {
    content: "-=[" !important;
  }
  &::after {
    content: "]=-" !important;
  }
}

[data-theme="walkthrough"] h2,h3,h4,h5,h6 {
  &::before {
    content: "=[" !important;
  }
  &::after {
    content: "]=" !important;
  }
}

[data-theme="walkthrough"] h1,h2,h3,h4,h5,h6 {
  text-transform: uppercase !important;
  font-size: 1rem;
  font-weight: bolder;
}

[data-theme="walkthrough"] hr {
  display: none !important;
}

[data-theme="walkthrough"] ul {
  list-style-type: "* ";
}

[data-theme="walkthrough"] .card {
  border-style: none !important;
  border-radius: 0 !important;
  
  border-color: $walkthrough-body-color-lightmode;
  @media (prefers-color-scheme: dark) {
    border-color: $walkthrough-body-color-darkmode;
  }
}

/*** IMAGES ***/
[data-theme="walkthrough"] img {
  display: none !important;
}


/*** PICO-8 ***/

[data-theme="walkthrough"] ul.pico8-cart-list {
  margin: 0;
  padding: 0;
}

[data-theme="walkthrough"] a.pico8-cart-title {
  &::before, &::after {
    content: "";
  }
}

[data-theme="walkthrough"] .pico8-cart-image-col {
  display: none !important;
}

[data-theme="walkthrough"] a.pico8-cart-image {
  &::before, &::after {
    content: "";
  }
}

[data-theme="walkthrough"] li.pico8-card {
  margin: 0 !important;
  padding: 0 !important;
  
  background-color: $walkthrough-bg-color-lightmode !important;
  @media (prefers-color-scheme: dark) {
    background-color: $walkthrough-bg-color-darkmode !important;
  }
  // &::before, &::after {
  //   content: "";
  // }
}

