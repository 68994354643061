/*** MASTER SYSTEM OVERRIDES ***/

[data-theme="mastersystem"] .ms-only {
    display:block !important;
}

[data-theme="mastersystem"] .ms-none {
    display:none !important;
}

[data-theme="mastersystem"] * {
    color: black !important;
    @media (prefers-color-scheme: dark) {
        color: black !important;
    }
}

[data-theme="mastersystem"] {
    color-scheme: light;
    @media (prefers-color-scheme: dark) {
        background-image: url("/assets/ms/gridbg.png");
        background-repeat: repeat;
    }
    background-image: url("/assets/ms/gridbg.png") ;
    background-repeat: repeat;
    font-family: serif;
}

[data-theme="mastersystem"] h1 {
    background-color: white;
}
[data-theme="mastersystem"] h2 {
    background-color: white;
}
[data-theme="mastersystem"] h3 {
    background-color: white;
}
[data-theme="mastersystem"] h4 {
    background-color: white;
}
[data-theme="mastersystem"] h5 {
    background-color: white;
}
[data-theme="mastersystem"] h6 {
    background-color: white;
}
[data-theme="mastersystem"] p {
    background-color: white !important;
}
[data-theme="mastersystem"] span {
    background-color: white !important;
}
[data-theme="mastersystem"] label {
    background-color: white;
}
[data-theme="mastersystem"] .longtext {
    background-color: white !important;
}
[data-theme="mastersystem"] li {
    background-color: white;
}

[data-theme="mastersystem"] img {
    border-radius: 1rem;
    border: 0.5rem solid #555;
}


[data-theme="mastersystem"] .longtext {
    text-shadow: 3px 3px $p8-lightgray;
    @media (prefers-color-scheme: dark) {
        text-shadow: 3px 3px $p8-lightgray;
    }
}

[data-theme="mastersystem"] .sga-font {
    font-family: "StandardGalactic8" !important;
    font-size: x-large;
  }

[data-theme="mastersystem"] .footer-line {
    border: 0.25em black dashed !important;
}

[data-theme="mastersystem"] .topnav {
    margin: 0;
    padding: 0;
    min-height: 6rem;
    // width: 4rem;
    background-color: transparent;
    background-image: url("/assets/ms/action.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    li > a {
        color: black !important;
        font-weight: bold;
        background-color: transparent !important;
        &:hover {
            background-color: transparent;
        }
    }
    @media (prefers-color-scheme: dark) {
        a {
            background-color: transparent;
            &:hover {
                background-color: transparent !important;
            }
        }
    }
    
}

[data-theme="mastersystem"] .navbutton-wrapper {
    background-color: transparent !important;
    p {
        width: 6rem;
        color: white;
        background-color: red !important;
        &:hover {
            font-weight: bolder;
            background-color: red !important;
        }
        rotate: 20deg;
    }
}

/*** PICO-8 CARDS ***/
[data-theme="mastersystem"] li.pico8-card {
    width: 100% !important;
    margin: 0;
    border: none !important;
    background: none !important;
    font-family: serif !important;
    img {
        display: inline-block !important;
        padding: 0 !important;
        margin: 0 !important;
    }
}

/*** CARD ***/
[data-theme="mastersystem"] .card {
    background-color: white;
}