/*** RETRO THEME OVERRIDES ***/
[data-theme="retro"] .retro-only {
  display: initial;
}

[data-theme="retro"] .retro-none {
  display: none;
}

[data-theme="retro"] {
  display: grid;
  grid-template-areas: 
      "sidebar"
      "content"
      "footer";
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  @include media-breakpoint-up("lg") {
    grid-template-areas: 
      "sidebar content"
      "sidebar footer";
    grid-template-columns: 10rem 1fr;
  }
  @media only print {
    grid-template-areas: "content";
    grid-template-rows: 1fr;
    grid-template-columns: 100%;
  }
  column-gap: 0;
  background-image: url("/assets/stars.gif") !important;
  @media (prefers-reduced-motion) {
    background-image: none !important; 
    background-color: darken(indigo,10%) !important;
  }
  color: lightgoldenrodyellow !important;
  font-family: "Times New Roman", serif !important;
}

[data-theme="retro"] .sga-font {
  font-family: "StandardGalactic8" !important;
  font-size: x-large;
}

// [data-theme="retro"] body {
//   height: 100vh;
// }

[data-theme="retro"] a {
  color: rgb(154, 154, 255);
  &:hover {
    color: white;
  }
  &:visited {
    color: orange;
  }
}

[data-theme="retro"] .container {
  width: 100%;
  max-width: 100%;
}

[data-theme="retro"] .page-content {
  grid-area: content;
}

[data-theme="retro"] .site-header {
  height: 100%;
  grid-area: sidebar;
  // grid-row: sidebar ;
}

[data-theme="retro"] .footer {
  grid-area: footer;
}

[data-theme="retro"] ul.topnav {
  // display: block;
  padding: 1rem;
  list-style-image: none;
  @include media-breakpoint-up("lg") {
    height: 100%;
  }
  li.sr-only {
    list-style-image: none;
  }
  li > a {
    display: block;
    @include media-breakpoint-up("lg") {
      padding: 0.5rem;
    }
    text-align: left;
    text-decoration: underline;
    @media (prefers-reduced-motion: no-preference) {
      list-style-image:  url("/assets/dance.gif");
    }
    &.nav-active {
      background-color: #333;
    }
  }
}


[data-theme="retro"] .footer-heading::before {
  @media (prefers-reduced-motion: no-preference) {
    content: url("/assets/yinyang.gif");
  }
}

[data-theme="retro"] hr {
  border-style: inset !important;
  border-width: thick !important;
}

[data-theme="retro"] ul {
  @media (prefers-reduced-motion: no-preference) {
    list-style-image: url("/assets/magic.gif");
  }
}

[data-theme="retro"] .longtext {
  text-shadow: 3px 3px darkslategrey;
}

[data-theme="retro"] .pico8-card {
  background-color: $p8-darkgray;
  border-color: $p8-darkpurple;
}