/*** VARIABLES ***/

$body-bg:           #e5e5e5;
$body-bg-dark:      #000;
$body-text:         #000;
$body-text-dark:    #eee;
$link:              rgb(34, 34, 238);
$link-dark:         #88f;
$link-hover:        #6666ff;
$link-hover-dark:   #aaf;
$link-visited:      $link;
$link-visited-dark: $link-dark;

$topnav-bg:             #221300;
$topnav-color:          #ffd8a8;
$topnav-color-dark:     #ea9a37;
$topnav-active:         $body-text;
$topnav-active-dark:    $body-text-dark;
$topnav-bg-active:      darken($body-bg,10%);
$topnav-bg-active-dark: lighten($body-text,20%);
$code:              #004;
$code-dark:         $body-text-dark;
$code-bg:           #cacaca;
$code-bg-dark:      #303030;

$link-light:          lighten($link,35%);
$link-hover-light:    lighten($link-hover,20%);
$link-visited-light:  $link-light;

$card-bg: lighten($body-bg,10%);


/*** COLOURS ***/
$p8-red:            #FF004D;
$p8-blue:           #29ADFF;
$p8-green:          #00E436;
$p8-yellow:         #FFEC27;
$p8-orange:         #FFA300;
$p8-peach:          #FFCCAA;
$p8-purple:         #83769C;
$p8-pink:           #FF77A8;
$p8-indigo:         #83769C;
$p8-brown:          #AB5236;
$p8-darkblue:       #1D2B53;
$p8-darkgreen:      #008751;
$p8-darkpurple:     #7E2553;
$p8-lightgray:      #C2C3C7;
$p8-darkgray:       #5F574F;

/*** LAYOUT VARIABLES ***/

$footer-min-height:   8rem;

/*** WALKTHROUGH THEME ***/
$walkthrough-bg-color-lightmode: #fff;
$walkthrough-body-color-lightmode: #000;
$walkthrough-topnav-bg-lightmode: darken($walkthrough-bg-color-lightmode,20%);
$walkthrough-topnav-active-lightmode: darken($walkthrough-bg-color-lightmode,30%);

$walkthrough-bg-color-darkmode: #000;
$walkthrough-body-color-darkmode: #fff;
$walkthrough-topnav-bg-darkmode: lighten($walkthrough-bg-color-darkmode, 5%);
$walkthrough-topnav-active-darkmode: lighten($walkthrough-bg-color-darkmode,15%);
