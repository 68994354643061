/*** FONT-AWESOME PATH ***/

$fa-font-path: "/assets/webfonts";


/*** IMPORTS ***/
@import "variables.scss";
@import "bs/bootstrap.scss";
@import "fa/fontawesome.scss";
@import "fa/solid.scss";
@import "generator.scss";
@import "darkmode.scss";
@import "retro.scss";
@import "walkthrough.scss";
@import "mastersystem.scss";

$color-mode-type: media-query;

/*** GENERAL ***/
:root {
  color-scheme: light dark;
}

.ascii-art {
  display: none;
}

.retro-only {
  display: none;
}

.ms-only {
  display: none;
}

/*** COLOURS ***/
body {
  background-color: $body-bg;
  color: $body-text;
  line-height: normal;
  font-size: 10pt;
  font-family: sans-serif;
  @media (prefers-color-scheme: dark) {
    background-color: $body-bg-dark;
    color: $body-text-dark;
  }
}

h1, h2, h3, h4, h5, h6, p, label {
  background: inherit;
  @media (prefers-color-scheme: dark) {
    background-color: inherit;
  }
}

@media (prefers-color-scheme: dark) {
  .bg-light {
    background-color: $body-bg-dark;
  }
  
  .bg-white {
    background-color: #000;
  }
  
  .bg-black {
    background-color: #eee;
  }
}

/*** TEXT ***/

a {
  color: $link;
  text-decoration: underline;
  &:hover {
    color: $link-hover;
  }
  &:visited {
    color: $link-visited;
  }
  text-transform: none;
  &::before, &::after, &:empty::before, &:empty::after{
    content: none !important;
  }
  @media (prefers-color-scheme: dark) {
  
      color: $link-dark;
      &:hover {
        color: $link-hover-dark;
      }
      &:visited {
        color: $link-visited-dark;
      }
    
  }
}


// .light-text {
//   color: $body-text;
//   a {
//     color: $link-light;
//     &:hover {
//       color: $link-hover-light;
//     }
//     &:visited {
//       color: $link-visited-light;
//     }
//   }
// }

@font-face {
  font-family: "SuperMarioMaker";
  src: url("/assets/webfonts/Super-Mario-Maker-2.woff2") format("woff2");
}

.maker-font {
  font-family: "SuperMarioMaker";
}

@font-face {
  font-family: "StandardGalactic";
  src: url("/assets/webfonts/Sga-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "StandardGalactic8";
  src: url("/assets/webfonts/sga_8.woff2") format("woff2");
}

.sga-font {
  font-family: "StandardGalactic" !important;
  font-size: xx-large;
}

span {
  background-color: transparent;
}

// code {
//   color: $code;
//   background-color: $code-bg;
// }

kbd {
  @media (prefers-color-scheme: dark) {
    background-color: $code-bg-dark;
  }
}

figure > pre {
  border-left: 0.5em solid black;
  @media (prefers-color-scheme: dark) {
    border-left: 0.5em solid grey;
  }
  border-radius: 0.5rem;
}
pre {
  padding: 0.5em 0.5em;
  background-color: $code-bg;
  // color: $code;
  // border-radius: 1em;
  // border-left: 0.5em solid $topnav-bg;
  @media (prefers-color-scheme: dark) {
    background-color: $code-bg-dark;
    color: $code-dark;
  }
  code {
    // color: $code;
    @media (prefers-color-scheme: dark) {
      background-color: $code-bg-dark;
      color: $code-dark;
    }
  }
}

pre.linenos {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none !important;
}

video {
  display: block;
  margin: 1em auto;
}



h1,h2,h3,h4,h5,h6 {
  text-transform: none !important;
  &::before, &::after {
    content: none !important;
  }
}

/*** UTLITIES ***/

.screen-only {
  display: none;
  @media only screen {
    display: inline;
  }
}

.rounded {
  border-radius: 1em;
}

.rounded-md-up {
  @include media-breakpoint-up(md) {
    border-radius: 1em;
  }
}

/*** LAYOUT ***/


// .container {
//   width: 90%;
//   @media (min-width: 400px) {
//     max-width: 1000px;
//   }
// }

.container-sm-up {
  width: 100%;
  margin: 0;
  padding: 0;
  @include media-breakpoint-up(sm) {
    @include make-container();
  }
}

.page-content {
  margin-top: 1em;
  margin-bottom: 2em;
  @include media-breakpoint-up(sm) {
    margin: 2em 0;
  }
}


.home {
  height: 100%;
  background: url("/assets/oats.jpg") no-repeat center center fixed;
  background-size: cover;
  color: white;
  text-shadow: 0.2em 0.2em black;
}

.post-list { list-style-type: none; }

.footer {
  min-height: $footer-min-height;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 1em;
  ul{
    list-style-type: none;
  }
}

/*** PICO-8 ***/

ul.pico8-cart-list {
  margin: 0;
  padding: 0;
}

// .pico8-cart-title {
//   color: $p8-blue;
//   // font-variant-caps: small-caps;
//   @media (prefers-color-scheme: dark) {
//     color: $p8-blue;
//   }
// }

li.pico8-card {
  width: 90%;
  @media only screen and (min-width: 550px) {
    width: 75%;
  }
  padding: 0.5em;
  margin: auto;
  margin-bottom: 1.5em;
  border: 0.3em solid $p8-darkgray;
  border-radius: 0.5em;
  background-color: $p8-lightgray;
  font-family: monospace;
  img {
    display: block;
    width: auto;
    max-width: 100%;
    // height: auto;
    margin: auto;
    padding: 1em 0;
    transition: 0.3s;
    &:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.38);
    }
    @media only screen and (min-width: 550px) {
      padding: 0;
    }
    @media (prefers-reduced-motion: no-preference) {
      transition: 0.3s;
    }
  }
  @media only screen and (max-width: 549px) {
    p {
      text-align: center;
      margin-bottom: 1.2em;
    }
    h4 {
      text-align: center;
    }
  }
  @media (prefers-color-scheme: dark) {
    background-color: $p8-darkgray;
    border: 0.3em solid $p8-lightgray;
  }
}

.pico8-cart-image {
  min-width: 160;
  min-height: 205;
}

/*** ETC ***/

figure {
  max-width: 90%;
  padding: 1em 0;
  @media only screen and (min-width: 550px) {
    max-width: 65%;
    padding: 1.5em 0;
  }
  margin: auto;
  .img {
    margin: 0;
    padding: 0;
  }
}

.longtext {
  background-color: inherit;
  font-family: serif;
  font-size: 2em;
  text-align: center;
  text-shadow: 3px 3px $p8-lightgray;
  @media (prefers-color-scheme: dark) {
    text-shadow: 3px 3px $p8-darkgray;
  }
}


.footer-line {
  border: 0.25em black dashed;
  margin: 1rem 0;
  padding: 0;
  @media (prefers-color-scheme: dark) {
    border: 0.25em $body-text-dark dashed;
  }
}

.pixelated {
  image-rendering: pixelated;
}

/*** NAV BAR ***/
.topnav {
  width: 100%;
  margin: 0;
  // @media only screen and (min-width: 550px) {
  //   width: 960px;
  // }
  background-color: $topnav-bg;
  overflow: hidden;
  list-style-type: none;
  a {
    float: left;
    display: block;
    text-align: center;
    padding: 1em;
    @include media-breakpoint-up(md) {
      padding: 0.6em 1em;
    }
    color: $topnav-color;
    text-decoration: none;
    &:hover {
      color: $topnav-active;
      background-color: $topnav-bg-active;
    }
  }
  .icon {
    display: none;
  }
  .nav-active {
    color: $topnav-active;
    background-color: $topnav-bg-active;
  }
  @media (prefers-color-scheme: dark) {
    a {
      color: $topnav-color-dark;
      &:hover {
        color: $topnav-active-dark;
        background-color: $topnav-bg-active-dark;
      }
    }
    .nav-active {
      background-color: $topnav-bg-active-dark;
      color: $topnav-active-dark;
    }
  }
}

@include media-breakpoint-down(sm) {
  .topnav a:not(:first-child) {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@include media-breakpoint-down(sm) {
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}

/*** BOOTSTRAP SHIT ***/

@include color-mode(dark) {
  .card {
      color: $body-text-dark;
      background-color: #222;
  }

  .figure-caption {
    color: $body-text-dark;
  }
}

/*** ICON STYLES ***/

.address {
  @extend %fa-icon;
  @extend .fas;

  &:before {
    content: fa-content($fa-var-address-book);
  }
}

/*** SYNTAX HIGHLIGHTING ***/
.highlight {
  .hll {
    background-color: #ffffcc;
  }
  // background: #f8f8f8;
  .c {
    color: #408080;
    font-style: italic;
  }
  .err {
    border: 1px solid #FF0000;
  }
  .k {
    color: #008000;
    font-weight: bold;
  }
  .o {
    color: #666666;
  }
  .cm {
    color: #408080;
    font-style: italic;
  }
  .cp {
    color: #BC7A00;
  }
  .c1, .cs {
    color: #408080;
    font-style: italic;
  }
  .gd {
    color: #A00000;
  }
  .ge {
    font-style: italic;
  }
  .gr {
    color: #FF0000;
  }
  .gh {
    color: #000080;
    font-weight: bold;
  }
  .gi {
    color: #00A000;
  }
  .go {
    color: #808080;
  }
  .gp {
    color: #000080;
    font-weight: bold;
  }
  .gs {
    font-weight: bold;
  }
  .gu {
    color: #800080;
    font-weight: bold;
  }
  .gt {
    color: #0040D0;
  }
  .kc, .kd, .kn {
    color: #008000;
    font-weight: bold;
  }
  .kp {
    color: #008000;
  }
  .kr {
    color: #008000;
    font-weight: bold;
  }
  .kt {
    color: #B00040;
  }
  .m {
    color: #666666;
  }
  .s {
    color: #BA2121;
  }
  .na {
    color: #7D9029;
  }
  .nb {
    color: #008000;
  }
  .nc {
    color: #0000FF;
    font-weight: bold;
  }
  .no {
    color: #880000;
  }
  .nd {
    color: #AA22FF;
  }
  .ni {
    color: #999999;
    font-weight: bold;
  }
  .ne {
    color: #D2413A;
    font-weight: bold;
  }
  .nf {
    color: #0000FF;
  }
  .nl {
    color: #A0A000;
  }
  .nn {
    color: #0000FF;
    font-weight: bold;
  }
  .nt {
    color: #008000;
    font-weight: bold;
  }
  .nv {
    color: #19177C;
  }
  .ow {
    color: #AA22FF;
    font-weight: bold;
  }
  .w {
    color: #bbbbbb;
  }
  .mf, .mh, .mi, .mo {
    color: #666666;
  }
  .sb, .sc {
    color: #BA2121;
  }
  .sd {
    color: #BA2121;
    font-style: italic;
  }
  .s2 {
    color: #BA2121;
  }
  .se {
    color: #BB6622;
    font-weight: bold;
  }
  .sh {
    color: #BA2121;
  }
  .si {
    color: #BB6688;
    font-weight: bold;
  }
  .sx {
    color: #008000;
  }
  .sr {
    color: #BB6688;
  }
  .s1 {
    color: #BA2121;
  }
  .ss {
    color: #19177C;
  }
  .bp {
    color: #008000;
  }
  .vc, .vg, .vi {
    color: #19177C;
  }
  .il {
    color: #666666;
  }
  @media (prefers-color-scheme: dark) {
    .hll {
      background-color: #49483e;
    }
    .c {
      color: #a8a38d;
    }
    .err {
      color: #960050;
      background-color: #1e0010;
    }
    .k {
      color: #66d9ef;
    }
    .l {
      color: #ae81ff;
    }
    .n {
      color: #f8f8f2;
    }
    .o {
      color: #f92672;
    }
    .p {
      color: #f8f8f2;
    }
    .cm, .cp, .c1, .cs {
      color: #a8a38d;
    }
    .ge {
      font-style: italic;
    }
    .gs {
      font-weight: bold;
    }
    .kc, .kd {
      color: #66d9ef;
    }
    .kn {
      color: #f92672;
    }
    .kp, .kr, .kt {
      color: #66d9ef;
    }
    .ld {
      color: #e6db74;
    }
    .m {
      color: #ae81ff;
    }
    .s {
      color: #e6db74;
    }
    .na {
      color: #a6e22e;
    }
    .nb {
      color: #f8f8f2;
    }
    .nc {
      color: #a6e22e;
    }
    .no {
      color: #66d9ef;
    }
    .nd {
      color: #a6e22e;
    }
    .ni {
      color: #f8f8f2;
    }
    .ne, .nf {
      color: #a6e22e;
    }
    .nl, .nn {
      color: #f8f8f2;
    }
    .nx {
      color: #a6e22e;
    }
    .py {
      color: #f8f8f2;
    }
    .nt {
      color: #f92672;
    }
    .nv {
      color: #f8f8f2;
    }
    .ow {
      color: #f92672;
    }
    .w {
      color: #f8f8f2;
    }
    .mf, .mh, .mi, .mo {
      color: #ae81ff;
    }
    .sb, .sc, .sd, .s2 {
      color: #e6db74;
    }
    .se {
      color: #ae81ff;
    }
    .sh, .si, .sx, .sr, .s1, .ss {
      color: #e6db74;
    }
    .bp, .vc, .vg, .vi {
      color: #f8f8f2;
    }
    .il {
      color: #ae81ff;
    }
    .gh {}
    .gu {
      color: #75715e;
    }
    .gd {
      color: #f92672;
    }
    .gi {
      color: #a6e22e;
    }
  }
}