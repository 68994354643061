/*** DARK MODE OVERRIDES ***/

[data-theme="dark"] :root {
  color-scheme: dark;
}

[data-theme="dark"] {
  background-color: $body-bg-dark !important;
  color: $body-text-dark !important;
}

[data-theme="dark"] .bg-light {
  background-color: $body-bg-dark !important;
}

[data-theme="dark"] .bg-white {
  background-color: $body-bg-dark !important;
}

[data-theme="dark"] .bg-black {
  background-color: $body-bg !important;
}

[data-theme="dark"] a {
  color: $link-dark !important;
  &:hover {
    color: $link-hover-dark !important;
  }
  &:visited {
    color: $link-visited-dark !important;
  }
}

[data-theme="dark"] .text-muted {
  color: #ccc !important;
}

[data-theme="dark"] kbd {
  background-color: $code-bg-dark;
}

[data-theme="dark"] pre {
  background-color: black;
  color: $code-dark;
}

[data-theme="dark"] .footer-line {
  border: 0.25em $body-text-dark dashed !important;
}

[data-theme="dark"] .longtext {
  text-shadow: 3px 3px $p8-darkgray;
}

/*** MARIO GENERATOR ***/

[data-theme="dark"] #sideArea {
  background-color: darken(gray,20%);
}

[data-theme="dark"] #controlsContainerBackground {
  background-color: darken(gray, 15%) !important;
}

[data-theme="dark"] .item-wrapper {
  background-color: darken(gray,30%);
  .item-name-wrapper {
    // color: lighten(#5D2123,50%);
    color: #eee !important;
  }
}

[data-theme="dark"] #buttonBar {
  background-color: darken(gray, 10%);
  border-bottom: solid 0.1em darken(gray, 30%);

  a {
    color: $body-text-dark !important;
    &:visited {
      color: $body-text-dark !important;
    }
  }
}

/*** PICO-8 ***/

[data-theme="dark"] .pico8-cart-title {
  color: $p8-red !important;
}

[data-theme="dark"] .pico8-card {
  background-color: $p8-darkgray !important;
  border: 0.3em solid $p8-lightgray !important;
}

/*** TOPNAV ***/
[data-theme="dark"] .topnav {
  a {
    color: $topnav-color !important;
    &:hover {
      color: $topnav-active-dark !important;
      background-color: $topnav-bg-active-dark !important;
    }
  }
  .nav-active {
    background-color: $topnav-bg-active-dark !important;
    color: $topnav-active-dark !important;
  }
}
