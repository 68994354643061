/*** MARIO GENERATOR ***/

#generatorStart {
  a {
    text-decoration: none;
  }
}

.grid-area {
  display: grid;
  margin: auto;
  margin-bottom: 1rem;
  @include media-breakpoint-up(sm) {
    margin-bottom: 2rem;
  }
  grid-template-columns: 1fr;
  grid-template-rows:
    [side-area] auto
    [enemy-area] 2fr
    [gizmo-area] 2fr;
  @include media-breakpoint-up(md) {
      grid-template-rows: 1fr;
      grid-template-columns:
        [side-area] 1fr
        [enemy-area] 2fr
        [gizmo-area] 2fr;
  };
  justify-content: space-evenly;
  // align-items: center;
  // justify-items:auto;
  width: 100%;
  // max-width: 1200px;
}

#errorText, #stopButton, #powerupArea {
  display: none;
}

#startButtonText, #stopButtonText {
  display: inline;
}

#controlsArea {
  display: none;
}

#controlsContainerBackground {
  background-color: lighten(orange, 40%);
  @media (prefers-color-scheme: dark) {
    background-color: darken(gray, 15%);
  }
}

#buttonBar {
  background-color: lighten(orange, 15%);
  border-bottom: solid 0.1rem darken(orange, 10%);
  a {
    color: $body-text-dark;
    &:visited {
      color: $body-text-dark;
    }
  }
  @media (prefers-color-scheme: dark) {
    background-color: darken(gray, 10%);
    border-bottom: solid 0.1rem darken(gray, 30%);
    a {
      color: $body-text-dark !important;
      &:visited {
        color: $body-text-dark !important;
      }
    }
  }
}

// .enemy-area, .gizmo-area {
//   /* Hide item boxes until preloader is finished */
//   display: none;
//   margin: 0;
//   padding: 0;
//   width: 100%;
//   // @include media-breakpoint-up(sm) {
//   //   padding: 0.5rem;
//   // }
//   // @include media-breakpoint-up(md) {
//   //   padding: 2rem 0.5rem 1rem 0.5rem;
//   // }
//   // @include media-breakpoint-up(sm) {
//   //   grid-template-columns: repeat(3, 1fr);
//   // }
//   // @include media-breakpoint-up(md) {
//   //   grid-template-columns: repeat(4, 1fr);
//   // }
//   box-sizing: border-box;
// }

.enemy-area {
  background-image: none;
  background-color: #7686FF;
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: contain;
}

.gizmo-area {
  background-color: grey;
}

#enemyDiv, #gizmoDiv {
  display: grid;
  margin: 0;
  padding: 0;
  grid-template-columns: 1fr;
  grid-template-rows:
    [header] auto
    [items] 4fr;
}

#enemyArea, #gizmoArea {
  display: grid;
  margin: 0;
  padding: 0;
  grid-template-rows: repeat(2,1fr);
  grid-template-columns: repeat(2,1fr);
  justify-items:stretch;
  justify-content: center;
  align-items: center;
  align-content: center;
  // align-items: center;
  // align-content: center;
}

#sideArea {
  display: none;
  margin: 0;
  padding: 3rem 1rem 1rem 1rem;
  @include media-breakpoint-up(sm) {
    padding: 3rem;
  }
  background-color: orange;
  grid-template-rows: [logo] [game-style] [using] [powerup];
  grid-template-columns: 1fr;
  @media (prefers-color-scheme: dark) {
    background-color: darken(gray,20%);
  }
}

#styleImg {
  display: block;
  margin: auto;
  max-width: 100%;
}

#themeImg, #powerupImg {
  margin: 1rem;
  height: 3rem;
  display: inline-block;
}

#themeArea, #powerupArea {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.item-wrapper {
  margin: 0.5rem;
  @include media-breakpoint-up(lg) {
    margin: 1rem;
  }
  padding: 0;
  // max-width: 15rem;
  height: auto;
  list-style-type: none;
  border: 0.5rem #FFD302 solid;
  text-align: center;
  background-color: #FFFEEF;
  .item-image {
    width: 100%;
  }
  .item-name-wrapper {
    color: #5D2123;
    height: 3rem;
    font-family: "SuperMarioMaker";
    font-size: 1.1em;
    font-weight: bold;
  }
  @media (prefers-color-scheme) {
    background-color: darken(gray,30%);
    .item-name-wrapper {
      // color: lighten(#5D2123,50%);
      color: #eee !important;
    }
  }
}
